import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Navbar} from 'react-bootstrap'
import { useStaticQuery, graphql } from "gatsby"

import ImageModule from "gatsby-theme-starberry-lomondgroup/src/modules/image_module.js";


// Images

const HeaderLogo = (props) => {

  const data = useStaticQuery(graphql`
  query HeaderLogoQueryTheme{
    glstrapi {

      siteConfig {
        Header_Logo {
          url
          alternativeText
        }
        Header_Bg_White_Logo{
          url
          alternativeText
        }
        Collection_Logo {
          alternativeText
          url
        }
      }
    }
  }`)

  var header_data = data.glstrapi;

  //console.log("menu_data", props?.GQLPage?.menu)

  return (
    <React.Fragment>
      <Navbar.Brand>
        <Link to="/">
          {(props?.GQLPage?.layout === "landing_template" || props?.GQLPage?.layout === "static_template" || props?.GQLPage?.layout === "team_template") ? (
            props?.GQLPage?.menu?.parent?.slug === "linleyandsimpson-collection" ?
              <ImageModule ImageSrc={header_data?.siteConfig?.Collection_Logo ? header_data?.siteConfig?.Collection_Logo : header_data?.siteConfig?.Header_Logo} altText={"Logo"} className="logo"/>
            :
              <ImageModule ImageSrc={header_data?.siteConfig?.Header_Logo} altText={"Logo"} className="logo"/>
            )
          :
          props?.GQLPage?.layout === "collection_page_template" ?
            <ImageModule ImageSrc={header_data?.siteConfig?.Collection_Logo ? header_data?.siteConfig?.Collection_Logo : header_data?.siteConfig?.Header_Logo} altText={"Logo"} className="logo"/>
            :
            <ImageModule ImageSrc={header_data?.siteConfig?.Header_Bg_White_Logo ? header_data?.siteConfig?.Header_Bg_White_Logo : header_data?.siteConfig?.Header_Logo} altText={"Logo"} className="logo"/>
          }                      
        </Link>
      </Navbar.Brand>
    </React.Fragment>
  )
}



export default HeaderLogo